import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  TextInput,
  ScrollView,
  Image,
} from "react-native";
import ReactDOMServer from "react-dom/server";
import { MAX_FILES_SIZE } from "@env";
import * as DocumentPicker from "expo-document-picker";
import { AntDesign, } from "@expo/vector-icons";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18n-js";
import { MailContext } from "../../../ContextData/Mail";
import PlayerSelect from "../../Elements/PlayerSelect.js";
import { editDate } from "../../../EditDate";
import {
  USER_GAME_NAME,
  USER_ID_IN_GAME,
  _NAME_FOR_GAME_,
} from "../../../config.inc";
import FileLimitPopup from "../../Elements/FileLimitPopup";
import { checkIfIsEncodeText } from '../../modules/removeSpacialCherectersAndSpaes';

import {
  setDraftsSystem,
  setErrorMessage,
  setTypeMinimize,
  minimizeNewMail,
  minimizeNewSystem,
  showFile,
  loading,
  setShowActions,
  showNewAction,
  deleteDraftsMail,
  minimizeDashbord,
} from "../../../redux/actions";
import { getContent } from "../../modules/getContent";
import ShortenWord from "../../modules/ShortenWord";
import UseResponsiveScreen from "../../modules/UseResponsiveScreen";
import EditorTiny from "../../editor/EditorTiny";
import SearchInput from "../../Elements/SearchInput";
import HeaderPopupButtons from "../../Elements/HeaderPopupButtons";
import { AllowFilesUploaded } from "../../Elements/AllowFilesUploaded";
import onClickEndGameCheck from "../../Elements/onClickEndGameCheck";
import { convertByte } from "../../modules/ConvertByte";

export default function NewMail({
  type,
  messageCurrent,
  messageType,
  setIndex,
  indexPopup,
  id,
  maximize,
  minimize,
  setMaximize,
  dashboard,
  setFlag,
  minimizeDashboardObject,
  minimizeDashboard,
  isMinimizeDashboard
}) {

  const { widthS, heightS } = UseResponsiveScreen();

  const { checkEnd } = onClickEndGameCheck();

  const fileRef = useRef(null);
  const sent = useRef(false);
  const cclineRef = useRef(null);
  const refCurrent = useRef(null);
  const isEdited = useRef(false);
  /* update componentWillUnmount only when the component is about to close and saving message to drafts
   only if the component is about to close */
  const componentWillUnmount = useRef(false);

  const dispatch = useDispatch();

  const background = useSelector((state) => state.style.background);
  const images = useSelector((state) => state.style.images);
  const color = useSelector((state) => state.style.color);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);

  const styles = stylesR(heightS, widthS, background, color, direction);

  const minimizeNewMailState = useSelector(
    (state) => state.minimize.minimizeNewMail
  );
  const minimizeNewSystemState = useSelector(
    (state) => state.minimize.minimizeNewSystem
  );
  const eventsState = useSelector((state) => state.events.events);

  const players =
    useSelector((state) => state.gameInfoArr.gameInfoArr.players) || [];
  const playerPermission = useSelector((state) => state.gameInfoArr.permission);

  const { sentMail, sentDraft, getSentMailData } = useContext(MailContext);

  const newMessageObject = {
    to_list: "",
    to_list_ids:
      type === "system" && playerPermission !== "1" && players?.filter((player) => player.permission_for_game === "1").length ===
        1
        ? players?.find((player) => player?.permission_for_game === "1").id
        : "",
    cc_list: "",
    cc_list_ids: "",
    from: "",
    from_id:
      playerPermission !== "1" ||
        (playerPermission === "1" && type === "system")
        ? sessionStorage.getItem(USER_ID_IN_GAME)
        : "",
    subject: "",
    body: "",
    bodyText: "",
    has_attachments: 0,
    attachments: [],
    old_files_list: [],
    message_parent_id: false,
    time: new Date().toString().slice(4),
  };

  const [newMessage, setNewMessage] = useState(newMessageObject);
  const [popupToList, setPopupToList] = useState(false);
  const [popupFromList, setPopupFromList] = useState(false);
  const [showCcLine, setShowCcLine] = useState(false);
  const [popupCcList, setPopupCcList] = useState(false);
  const [message, setMessage] = useState();
  const [err, setErr] = useState("");
  const [bodyDelay, setBodyDelay] = useState(false);
  const [subjectFocus, setSubjectFocus] = useState(false);
  const [widthInput, setWidthInput] = useState({
    to: widthS * 10,
    cc: widthS * 10,
  });
  const [inputSearch, setInputSearch] = useState({ to: "", cc: "", from: "" });
  const [fromPlayers, setFromPlayers] = useState([...players.filter(player => player.permission_for_game !== "1")]);
  const [toPlayers, setToPlayers] = useState([...players.filter(player => player.permission_for_game !== "1")]);
  const [showFileLimit, setShowFileLimit] = useState(false);
  const [drag, setDrag] = useState(false);


  const createFilesArray = async (res) => {
    return [] = await Promise.all(Object.keys(res.output).map(async (key) => {
      if (!AllowFilesUploaded.find((e) => res.output[key].type.indexOf(e) >= 0)) {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_files")}`,
          )
        );
        return;
      } else if (convertByte(res.output[key].size) >= MAX_FILES_SIZE) {
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_fileSize_mail")}`,
          )
        );
        return;
      }
      return createUri(res.output[key]).then((uri) => {
        let newFile = {
          name: res.output[key].name,
          type: res.output[key].type,
          uri: uri,
          file: res.output[key],
          size: res.output[key].size
        }
        return newFile;
      }).catch((err) => {
        dispatch(loading(false));
      });
    }));
  }

  const createUri = (file) => {
    return new Promise((reslove, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        reslove(reader.result);
      }
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  }

  const resetState = () => {
    setNewMessage(newMessageObject);
  }

  const openFilesBrowser = async () => {
    DocumentPicker.getDocumentAsync({
      multiple: true,
      allowsEditing: true,
      type: AllowFilesUploaded.join(","),
      aspect: [4, 3],
    }).then((res) => {
      createFilesArray(res).then((arr) => {
        dispatch(loading(false));
        arr = arr.filter(e => e);
        if (newMessage?.attachments?.length > 0) {
          setNewMessage({
            ...newMessage,
            attachments: [...arr, ...newMessage.attachments],
            has_attachments: `${arr.length || 0}`,
          });
        } else {
          setNewMessage({
            ...newMessage,
            attachments: [...arr],
            has_attachments: `${arr.length || 0}`,
          });
        }

      });
    });
  };

  const saveToDraftsHandler = async (messageBeforeChange) => {
    let message = messageBeforeChange || newMessage;
    if (type === "mail") {
      dispatch(loading(true));
      message["is_draft_message"] = true;
      if (message?.post) {
        let post = await message.post;
        if (typeof message?.post !== "string") {
          post = await document.createElement("div");
          post.innerHTML = await ReactDOMServer.renderToString(message.post);
          post = await post.innerHTML;
        }
        message.body = `${message.body} <br/> <br/> ${post}`;
      }
      sentMail(message).then(() => {
        dispatch(loading(false));
        resetState();
      }).catch(() => {
        dispatch(loading(false));
        resetState();
      });
    } else {
      dispatch(
        setDraftsSystem({
          ...message,
          body: isEmptyBody ? "" : message.body,
          id: message.id || Math.random(),
        })
      );
    }
  };

  const addNewMail = async () => {
    if (!newMessage.from_id) {
      setErr("from");
      return;
    }
    if (
      !newMessage.to_list_ids ||
      (newMessage.to_list_ids && newMessage.to_list_ids.length <= 0)
    ) {
      setErr("to");
      return;
    } else {
      setErr("");
    }
    if (
      !newMessage.subject ||
      (newMessage.subject && newMessage.subject.length < 3)
    ) {
      setErr("subject");
      return;
    } else {
      setErr("");
    }
    if (
      !newMessage.body ||
      (newMessage.body && newMessage.body.length === 0) ||
      (messageType !== "drafts" && !newMessage?.post &&
        Object.keys(messageCurrent).length > 0 &&
        getContent(newMessage.body)?.replace(/\s/g, "") ===
        messageCurrent?.body_search?.replace(/\s/g, ""))
    ) {
      setErr("body");
      return;
    } else {
      setErr("");
    }
    if ((!newMessage.event_id || newMessage.event_id === "") && type !== "system") {
      setErr("event");
      return;
    } else {
      setErr("");
    }
    dispatch(loading(true));
    if (newMessage.body.indexOf("<hr />") >= 0) {
      newMessage.body = newMessage.body.slice(
        0,
        newMessage.body.indexOf("<p>")
      );
    }
    // if (newMessage.body.indexOf("font-size") < 0) {
    //   newMessage.body = `<div style="font-size: 14px;">${newMessage.body}</div>`
    // }
    if (messageType === "news" || (newMessage.post && messageType !== "drafts")) {
      let post = newMessage.post;
      if (typeof newMessage?.post !== "string") {
        post = document.createElement("div");
        post.innerHTML = ReactDOMServer.renderToString(newMessage.post);
        post = post.innerHTML;
      }
      let newM = {
        ...newMessage,
        body: `${newMessage.body} <br/> <br/> ${post}`
      };
      sentMail(
        newM,
        messageType,
        message ? message.sender?.name : sessionStorage.getItem(USER_GAME_NAME)
      ).then(() => {
        dispatch(loading(false));
        resetState();
      }).catch(() => {
        dispatch(loading(false));
        resetState();
      });
    } else if (messageType === "drafts") {
      if (newMessage?.post) {
        let post = await newMessage.post;
        if (typeof newMessage?.post !== "string") {
          post = await document.createElement("div");
          post.innerHTML = await ReactDOMServer.renderToString(newMessage.post);
          post = await post.innerHTML;
        }
        newMessage.body = `${newMessage.body} <br/> <br/> ${post}`;
      }
      if (isEdited.current) {
        await sentMail(newMessage);
      }
      await sentDraft(newMessage).then(() => {
        if (+playerPermission === 15) {
          getSentMailData();
        }
        dispatch(loading(false));
        resetState();
      }).catch(() => {
        dispatch(loading(false));
        resetState();
      });
    } else {
      await sentMail(
        newMessage,
        messageType,
        message ? message.sender?.name : sessionStorage.getItem(USER_GAME_NAME)
      ).then(() => {
        dispatch(loading(false));
        resetState();
      }).catch(() => {
        dispatch(loading(false));
        resetState();
      });
    }
    sent.current = true;
    // need to get response from sentMail if the message successfully sent and then close the popup

    if (messageType === "drafts") deleteDraftHandler(true, true);
    if (dashboard) {
      return setFlag();
    }
    await dispatch(
      type === "mail"
        ? minimizeNewMail(false, false, id, true)
        : minimizeNewSystem(false, false, id, true)
    );
  };

  const newReplyForward = () => {
    if (type === "mail") {
      switch (messageType) {
        case "news":
          setNewMessage({
            ...newMessage,
            from: message?.sender?.name,
            to_list: messageCurrent?.to_list_names,
            to_list_ids: messageCurrent?.to_list,
            body: message?.body,
            post: messageCurrent?.post,
            event_id: messageCurrent?.event_id,
            event: messageCurrent?.event,
          });
          return;
        case "reply":
          if (message?.message) {
            setNewMessage({ ...message });
            return;
          }
          setNewMessage({
            ...newMessage,
            message_parent_id: message.id,
            to_list: message.sender.name,
            to_list_ids: message.sender.id,
            subject: `Re: ${message.subject.replace("Re: ", "")}`,
            message: message,
            has_attachments: message.has_attachments,
            old_files_list: message.attachments,
            event_id: message?.event?.id,
            event: message?.event?.name,
          });
          return;
        case "reply All":
          let messageC = message.message || message;
          let to_list_namesNew = messageC.to_list_names && messageC.to_list_names.length > 0 ? messageC.to_list_names + `,${messageC.sender.name}` : messageC.sender.name;
          let to_listNew = messageC.to_list && messageC.to_list.length > 0 ? messageC.to_list + `,${messageC.sender.id}` : messageC.sender.id;

          let cc_list_namesNew = messageC.cc_list_names && messageC.cc_list_names.length > 0 ? messageC.cc_list_names : "";
          let cc_listNew = messageC.cc_list && messageC.cc_list.length > 0 ? messageC.cc_list : "";

          let bcc_list_namesNew = messageC.bcc_list_names && messageC.bcc_list_names.length > 0 ? messageC.bcc_list_names : "";
          let bcc_listNew = messageC.bcc_list && messageC.bcc_list.length > 0 ? messageC.bcc_list : "";
          //comment
          to_list_namesNew = to_list_namesNew.replace(`${sessionStorage.getItem(USER_GAME_NAME)}` || `${sessionStorage.getItem(USER_GAME_NAME)},`, "");
          to_listNew = to_listNew.replace(`${sessionStorage.getItem(USER_ID_IN_GAME)}` || `${sessionStorage.getItem(USER_ID_IN_GAME)}`, "");
          cc_list_namesNew = cc_list_namesNew.replace(`${sessionStorage.getItem(USER_GAME_NAME)}` || `${sessionStorage.getItem(USER_GAME_NAME)},`, "");
          cc_listNew = cc_listNew.replace(`${sessionStorage.getItem(USER_ID_IN_GAME)}` || `${sessionStorage.getItem(USER_ID_IN_GAME)}`, "");
          bcc_list_namesNew = bcc_list_namesNew.replace(`${sessionStorage.getItem(USER_GAME_NAME)}` || `${sessionStorage.getItem(USER_GAME_NAME)},`, "");
          bcc_listNew = bcc_listNew.replace(`${sessionStorage.getItem(USER_ID_IN_GAME)}` || `${sessionStorage.getItem(USER_ID_IN_GAME)}`, "");
          if (message?.message) {
            setNewMessage({
              ...message,
              to_list: to_list_namesNew,
              to_list_ids: to_listNew,
              cc_list: cc_list_namesNew,
              cc_list_ids: cc_listNew,
              bcc_list: bcc_list_namesNew,
              bcc_list_ids: bcc_listNew,
            });
            return;
          }
          setNewMessage({
            ...newMessage,
            message_parent_id: message.id,
            to_list: to_list_namesNew,
            to_list_ids: to_listNew,
            subject: `Re: ${message.subject.replace("Re: ", "")}`,
            cc_list: cc_list_namesNew,
            cc_list_ids: cc_listNew,
            bcc_list: bcc_list_namesNew,
            bcc_list_ids: bcc_listNew,
            has_attachments: message.has_attachments,
            old_files_list: message.attachments,
            message: message,
            event_id: message?.event?.id,
            event: message?.event?.name,
          });
          return;
        case "forward":
          if (message?.message) {
            setNewMessage({ ...message });
            return;
          }
          setNewMessage({
            ...newMessage,
            message_parent_id: message.id,
            subject: `Fwd: ${message.subject.replace("Fwd: ", "")}`,
            has_attachments: message.has_attachments,
            old_files_list: message.attachments,
            has_attachments: [],
            message: message?.message || message,
            event_id: message?.event?.id,
            event: message?.event?.name,
          });
          return;
        case "drafts":
          const divRegex = /<div id='forward_reply_prefix'>/;
          const divRegexPost = /<span id="news-post-mail"/;
          const brRegex = /(<br\s*\/?>\s*){3}/;

          const matchDiv = message.body && message.body.match(divRegex);
          const matchPost = message.body && message.body.match(divRegexPost);

          let divIndex = matchDiv ? matchDiv.index : -1;
          let postIndex = matchPost ? matchPost.index : -1;

          let bodyBeforeDiv = '';
          let bodyPost = '';
          let bodyAfterDiv = '';

          if (divIndex !== -1 || postIndex !== -1) {
            if (divIndex !== -1 && (postIndex === -1 || divIndex < postIndex)) {
              // div הוא הראשון או ש-post לא קיים
              bodyBeforeDiv = message.body.slice(0, divIndex);
              bodyAfterDiv = message.body.slice(divIndex).replace(brRegex, '');
            } else if (postIndex !== -1) {
              // post קיים והוא לפני div
              bodyBeforeDiv = message.body.slice(0, postIndex);
              bodyPost = message.body.slice(postIndex, divIndex).replace(brRegex, '');
              bodyAfterDiv = message.body.slice(divIndex).replace(brRegex, '');
            }
          } else {
            bodyBeforeDiv = message.body;
          }

          return setNewMessage({
            ...newMessage,
            ...(message?.event?.name !== "null" ? {
              event_id: message?.event?.id,
              event: message?.event?.name,
            } : {}),
            is_draft_message: true,
            id: message?.id,
            to_list: message?.to_list_names,
            to_list_ids: message?.to_list,
            cc_list: message?.cc_list_names,
            cc_list_ids: message?.cc_list,
            bcc_list: message?.bcc_list_names,
            bcc_list_ids: message?.bcc_list,
            from: message?.sender?.name,
            from_id: message?.sender?.id,
            time: message?.time,
            subject: message?.subject,
            flag: message?.flag,
            old_files_list: message?.attachments,
            has_attachments: [],
            attachments: false,
            message_parent_id: message?.message_parent_id,
            bodyText: message?.body_search,
            body: bodyBeforeDiv,
            ...(bodyAfterDiv && {
              body_reply: bodyAfterDiv
            }),
            ...(bodyPost && {
              post: bodyPost
            })
          });
        case "dashboard":
          setNewMessage({
            ...newMessage,
            ...message,
          });
          return;
      }
    } else {
      if (messageType === "reply") {
        setNewMessage({
          ...newMessage,
          message_parent_id: message.id,
          from: sessionStorage.getItem(USER_GAME_NAME),
          from_id: sessionStorage.getItem(USER_ID_IN_GAME),
          to_list: message.sender.name,
          to_list_ids: message.sender.id,
          subject: `Re: ${message.subject.replace("Re: ", "")}`,
          message: message,
          has_attachments: [],
          old_files_list: message.attachments,
          // body: message.body,
        });
      } else if (messageType === "drafts") {
        setNewMessage({
          ...newMessage,
          ...message,
        });
      }
    }
  };

  const deleteDraftHandler = async (e, send) => {
    if (e) {
      if (messageType === "drafts") {
        if (send) {
          dispatch(deleteDraftsMail(newMessage));
          return;
        } else {
          message.is_delete = true;
          dispatch(loading(true));
          sentMail(message).then(() => {
            dispatch(minimizeNewMail(false, false, id, true));
            setIndex("");
            dispatch(loading(false));
          }).catch((e) => {
            dispatch(loading(false));
          });
        }
      } else {
        sent.current = true;
        if (dashboard) {
          dispatch(minimizeDashbord(false, false, id, true));
          return;
        }
        if (type === "mail") {
          dispatch(minimizeNewMail(false, false, id, true));
        } else {
          dispatch(dispatch(minimizeNewSystem(false, false, id, true)));
        }
        return;
      }
    }
  };

  const createId = (type, msgType) => {
    if (msgType === "drafts") {
      return "mytoolber";
    } else {
      if (type === "mail") {
        return `toolberMail${indexPopup}`;
      } else {
        return `toolberSystem${indexPopup}`;
      }
    }
  };

  useEffect(() => {
    setMessage({ ...messageCurrent });
  }, [messageCurrent]);

  useEffect(() => {
    setInputSearch({ to: "", cc: "", from: "" });
  }, [popupCcList, popupToList, popupFromList]);

  useEffect(() => {
    if (newMessage?.to_list?.length > 0) {
      setWidthInput({ ...widthInput, to: widthS * 20 });
    } else {
      setWidthInput({ ...widthInput, to: "100%" });
    }
  }, [newMessage?.to_list]);

  useEffect(() => {
    if (newMessage?.cc_list?.length > 0) {
      setWidthInput({ ...widthInput, cc: widthS * 20 });
    } else {
      setWidthInput({ ...widthInput, cc: "100%" });
    }
  }, [newMessage?.cc_list]);

  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
    };
  }, []);

  const hasNonEmptyValue = (obj, keysToCheck) => {
    return keysToCheck.some(key => {
      let value = obj[key];
      // בדיקה אם הערך הוא מחרוזת ולא ריקה לאחר trim
      if (typeof value === 'string') {
        return value.trim() !== "";
      }
      // בדיקה עבור ערכים שאינם מחרוזות (כגון מערכים)
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      // בדיקה עבור ערכים שאינם null או false
      return value !== null && value !== false;
    });
  }

  useEffect(() => {
    const keysToCheck = ["to_list", "to_list_ids", "cc_list", "cc_list_ids", "from", "subject", "body", "bodyText", "attachments", "old_files_list", "message_parent_id"];
    // דוגמה לשימוש בפונקציה
    const hasValue = hasNonEmptyValue(newMessage, keysToCheck);
    if (hasValue) {
      isEdited.current = true;
    } else {
      isEdited.current = false;
    }
    return () => {
      if (componentWillUnmount.current && messageCurrent
        && !sent.current
      ) {
        // --------------------------Draft---------------------------------
        if (isEdited.current && !isMinimizeDashboard) {
          saveToDraftsHandler();
        }
        if ((isEdited.current || isMinimizeDashboard?.obj) && isMinimizeDashboard?.show) {
          if (isMinimizeDashboard?.typeMessage !== "") {
            dispatch(minimizeDashbord(true, isEdited.current ? newMessage : null, isMinimizeDashboard?.show, isMinimizeDashboard?.typeMessage));
          }
          dispatch(minimizeDashbord(true, isEdited.current ? newMessage : null, isMinimizeDashboard?.show))
        }
        // ----------------------------------------------------------------
      }
    };
  }, [newMessage]);

  // filtering players from to and cc by from
  useEffect(() => {
    setToPlayers(
      players.filter(
        (player) =>
          player.permission_for_game !== "1" && player.id !== newMessage?.from_id
      )
    );
    if (newMessage?.from_id?.length > 0) {
      // פיצול המחרוזת למערך, הסרת רווחים והסרת המזהה של השולח
      let to = newMessage.to_list.split(',')
        .map(id => id.trim())
        .filter(id => id?.toLocaleLowerCase() !== newMessage.from?.toLocaleLowerCase())
        .join(',');

      let to_id = newMessage.to_list_ids.split(',')
        .map(id => id.trim())
        .filter(id => id !== newMessage.from_id)
        .join(',');

      // עדכון ההודעה החדשה עם הערכים המעודכנים
      setNewMessage({ ...newMessage, to_list: to, to_list_ids: to_id });
    }
  }, [newMessage?.from_id]);

  // handle the new message if is reply or forwarding
  useEffect(() => {
    if ((!newMessage.message_parent_id || newMessage.message_parent_id?.length === 0 || message?.id !== newMessage?.id) && !newMessage?.post && message) {
      newReplyForward();
    }
  }, [messageType, message]);

  // delay for content when minimized the window 
  useEffect(() => {
    if (!dashboard) {
      if (!minimize) {
        setTimeout(() => setBodyDelay(true), 300);
      } else if (minimize) {
        setBodyDelay(false);
      }
    } else {
      setBodyDelay(true);
    }
  }, [minimize]);

  // close cc field if it's empty and user click outside cc button
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (cclineRef.current && !cclineRef.current.contains(e.target) && newMessage.cc_list_ids?.length === 0) {
        setShowCcLine(false);
      }
    });
  }, [cclineRef.current]);

  // add event function for callChallenges linking
  useEffect(() => {
    if (document.getElementById("callChallenges")) {
      document.getElementById("callChallenges").addEventListener("click", () => {
        dispatch(setShowActions(true));
        dispatch(setTypeMinimize({ name: "challenges", type: 1, show: true, num: 5 }));
        dispatch(showNewAction(true))
      })
    }
  }, [document.getElementById("callChallenges"), newMessage?.message?.body]);

  // block refresh if hes changes 
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    if (isEdited.current) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    }
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isEdited.current]);

  const checkIfIsCallForAction = (message) => {
    let div = document.createElement("div");
    div.innerHTML = message;
    Object.keys(div.children).forEach((key) => {
      if (div.children[key]?.href) {
        div.children[key].id = "callChallenges";
      }
    });
    return div.innerHTML;
  }

  const fileItem = (file, i) => (
    <View
      style={{
        borderRadius: "0.5vw",
        width: "15vw",
        borderWidth: "0.1vw",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: "0.5vw",
        borderColor: color.mailBorder2,
        marginVertical: "0.1vw"
      }}
    >
      <Pressable
        style={[
          styles.label_text_input,
          {
            color: color.logoColor,
            width: "1vw",
          },
        ]}
        onPress={() => {
          if (newMessage.attachments?.length > 0 && Array.isArray(newMessage.attachments) && newMessage.attachments.findIndex((fileItem) => fileItem?.id === file?.id || fileItem?.size === file?.size) >= 0) {
            let temp = [...newMessage.attachments]
              ; temp.splice(i, 1);
            setNewMessage({ ...newMessage, attachments: [...temp], has_attachments: temp.length })
          } else {
            let temp = [...newMessage.old_files_list];
            temp.splice(i, 1);
            setNewMessage({ ...newMessage, old_files_list: [...temp], has_attachments: temp.length })
          }
        }}
      >
        <AntDesign name="close" style={styles.label_text_input} color={color.logoColor} />
      </Pressable>
      <Pressable
        style={{
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          justifyContent: "center"
        }}
        onPress={() =>
          dispatch(
            showFile({
              name: file.name,
              link: file.uri || file.link,
              type: file.type,
            })
          )
        }
      >
        <Text
          numberOfLines={1}
          selectable={false}
          style={[
            styles.label_text_input,
            {
              direction: lang === "he" ? "rtl" : "ltr",
              color: color.logoColor,
            },
          ]}
        >
          {`${ShortenWord(file.name, 15)}`}
        </Text>
        {file?.size
          &&
          <Text
            style={[
              styles.label_text_input,
              { color: color.logoColor, fontFamily: "Catamaran_300Light", },
            ]}
          >
            {`  (${file.size >= 1000000000
              ? +(
                file.size / 1000000000
              ).toFixed(1) + "Gb"
              : file.size >= 1000000
                ? +(
                  file.size / 1000000
                ).toFixed(1) + "Mb"
                : Math.ceil(file.size / 1000) +
                "Kb"
              }) `}
          </Text>}
      </Pressable>
    </View>
  )

  const newMailBodyDisplay = () => {
    let post = message?.post || newMessage?.post;
    return (
      <ScrollView
        nativeID="new-mail-draft"
        style={[styles.body,
        {
          backgroundColor: background.mailBody,
          overflow: "auto",
          opacity: drag ? 0.5 : 1,
          flex: 1
        },
        err !== "" && !newMessage.body ? styles.mail_error : "",
        ]}
        contentContainerStyle={styles.body_m}
      >
        {(messageCurrent || messageType !== "drafts") && (
          <View style={{ justifyContent: "space-between", backgroundColor: background.mailBody }}>
            <Text style={[styles.main, { minHeight: "10vw" }]}>
              <EditorTiny
                colorSkin={false}
                colorIcon={"black"}
                id={createId(type, messageType)}
                messageType={messageType}
                refCurrent={refCurrent}
                setImagesBody={(images) => setNewMessage({ ...newMessage, body_images: images })}
                newMessage={{ body: newMessage.body }}
                setNewMessage={(newM) => {
                  setNewMessage({ ...newMessage, body: newM });
                }}
              />
            </Text>
          </View>
        )}
        {newMessage?.body_reply && <Text selectable={true} style={[styles.label_reply]}>
          <div dangerouslySetInnerHTML={{ __html: newMessage?.body_reply }} />
        </Text>}
        {newMessage.message?.body &&
          (messageType === "reply" ||
            messageType === "reply All" ||
            messageType === "forward" || messageType === "drafts") && (
            <View
              style={{
                flex: 1,
                minHeight: heightS * 50,
                paddingVertical: heightS * 10,
              }}
            >
              <Text selectable={true} style={[styles.label_reply]}>
                ------- {t("Forwarded_message")} ------
              </Text>
              <Text selectable={true} style={[styles.label_reply]}>
                {t("from")}: {checkIfIsEncodeText(newMessage.message.sender?.name, "string")}
              </Text>
              <Text selectable={true} style={[styles.label_reply]}>
                {t("sent")}:
                {message &&
                  `${editDate(newMessage.message.time, "mail", "mail")} | ${editDate(
                    newMessage.message.time,
                    "mail",
                    "clock"
                  )}`}
              </Text>
              <Text selectable={true} style={[styles.label_reply]} >
                {t("to")}: {checkIfIsEncodeText(newMessage.message.to_list_names, "string")}
              </Text>
              <Text selectable={true} style={[styles.label_reply]}>
                {t("subject")}: {checkIfIsEncodeText(newMessage.message.subject, "string")}
              </Text>
              <Text selectable={true} style={[styles.label_reply, { paddingTop: heightS * 20, }]}>
                <div dangerouslySetInnerHTML={{ __html: newMessage.message.body }} />
              </Text>
            </View>
          )}
        {post && (() => {
          return <div style={{ width: "100%", display: "flex", justifyContent: "center" }} dangerouslySetInnerHTML={{ __html: typeof post === "string" ? post : ReactDOMServer.renderToString(post) }}></div>
        })()}
        {(() => {
          if (newMessage.attachments || newMessage?.old_files_list) {
            return (
              <View>
                <View
                  style={[styles.attach_view]}
                >
                  {newMessage.attachments?.length > 0 && newMessage.attachments?.map((file, i) => {
                    return (
                      fileItem(file, i)
                    )
                  })}
                  {newMessage?.old_files_list?.length > 0 ? newMessage?.old_files_list.map((file, i) => {
                    return (
                      fileItem(file, i)
                    )
                  }) : null}
                </View>
              </View>
            )
          }
        })()}
      </ScrollView>
    )
  }
  const body = () => {
    return (
      <View style={[styles.containerBody, messageType === "drafts" && { flex: 1 }]}>
        {bodyDelay && <>
          {(messageCurrent || messageType !== "drafts") && (
            <View style={styles.header}>
              {playerPermission === "1" &&
                <View style={styles.from_view}>
                  <Text selectable={false} style={[styles.label_for]}>
                    {t("from")}:
                  </Text>
                  <SearchInput
                    newMail={true}
                    playersListProp={fromPlayers}
                    styleType={{ dark: false, type: "from", check: false }}
                    value={newMessage}
                    setValue={(message) => {
                      setNewMessage({ ...newMessage, from: message.from, from_id: message.from_id });
                    }}
                    system={type == "system"}
                    err={err}
                    styles={styles}
                  />
                </View>}
              <View style={[styles.to_view, !showCcLine && newMessage.cc_list_ids?.length === 0 && styles.borderBottomHeader]}>
                <View style={styles.to_view_child}>
                  <Text selectable={false} style={[styles.label_for]}>
                    {t("to")}:
                  </Text>
                  <SearchInput
                    newMail={true}
                    playersListProp={toPlayers}
                    styleType={{ dark: false, type: "to", check: true }}
                    value={newMessage}
                    setValue={(message) => {
                      setNewMessage({ ...newMessage, to_list: message.to_list, to_list_ids: message.to_list_ids });
                    }}
                    system={type == "system"}
                    err={err}
                    styles={styles}
                  />
                </View>
                {!showCcLine && newMessage.cc_list_ids?.length === 0 && <Pressable onPress={() => setShowCcLine(true)}>
                  <Text>{t("cc")}</Text>
                </Pressable>}
              </View>
              {(type === "mail" && showCcLine || newMessage.cc_list_ids?.length > 0) && (
                <View style={styles.cc_view} ref={cclineRef}>
                  <Text selectable={false} style={[styles.label_for]}>
                    {t("cc")}:{" "}
                  </Text>
                  <SearchInput
                    newMail={true}
                    playersListProp={players}
                    styleType={{ dark: false, type: "cc", check: true }}
                    value={newMessage}
                    setValue={(message) => {
                      setNewMessage({ ...newMessage, cc_list: message.cc_list, cc_list_ids: message.cc_list_ids });
                    }}
                    system={type == "system"}
                    err={err}
                    styles={styles}
                  />
                </View>
              )}
              {type !== "system" && <View style={styles.event_view}>
                <Text selectable={false} style={[styles.label_for]}>
                  {t("event")}:
                </Text>
                <SearchInput
                  newMail={true}
                  playersListProp={eventsState?.filter((e) => e.name)}
                  styleType={{ dark: false, type: "event", check: false }}
                  value={newMessage}
                  setValue={(event) => {
                    setNewMessage({ ...newMessage, event_id: event.event_id, event: event.event });
                  }}
                  err={err}
                  styles={styles}
                />
              </View>}
              <View
                style={styles.subject_view}
              >
                <Text
                  selectable={false}
                  style={[styles.label_for]}
                >
                  {t("subject")}:{" "}
                </Text>
                <TextInput
                  selectTextOnFocus={false}
                  onFocus={() => setSubjectFocus(true)}
                  onBlur={() => setSubjectFocus(false)}
                  style={[
                    styles.inp_subject,
                    {
                      outline: "none",
                    },
                    {
                      paddingLeft: widthS * 3,
                      direction: lang === "he" ? "rtl" : "ltr",
                      transition: "all 0.3s",
                    },
                    err !== "" && (!newMessage.subject || newMessage.subject.length < 3) ? styles.mail_error : "",
                  ]}
                  value={newMessage.subject}
                  onChangeText={(txt) => {
                    if (
                      messageType === "forward" &&
                      newMessage.subject.indexOf("Fwd:") < 0
                    ) {
                      setNewMessage({
                        ...newMessage,
                        subject: `Fwd: ${txt.replace("Fwd: ", "")}`,
                      });
                    } else if (
                      (messageType === "reply" || messageType === "reply All") &&
                      newMessage.subject.indexOf("Re:") < 0
                    ) {
                      setNewMessage({
                        ...newMessage,
                        subject: `Re: ${txt.replace("Re: ", "")}`,
                      });
                    } else {
                      if (messageType === "") {
                        setNewMessage({
                          ...newMessage,
                          subject: txt,
                        });
                      } else {
                        setNewMessage({
                          ...newMessage,
                          subject: `${newMessage.subject.slice(0, ":")}${txt}`,
                        });
                      }
                    }
                  }}
                />
              </View>
            </View>
          )}
          {newMailBodyDisplay()}
          {/*{(messageCurrent || messageType !== "drafts") && (*/}
          <View style={[styles.footer, { borderTopColor: color.mailBorder2, backgroundColor: background.mailBody }]}>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                minWidth: messageType === "drafts" ? widthS * 193 : widthS * 162,
              }}
            >
              <Pressable
                onPress={() => {
                  dispatch(
                    setErrorMessage(
                      true,
                      undefined,
                      `${t("would_you_like_to_delete_this_email")}?`,
                      deleteDraftHandler
                    )
                  );
                }}
                style={({ hovered }) => [
                  hovered ? styles.toolbar_btns_hovered : styles.toolbar_btns,
                  {},
                ]}
              >
                <Image
                  source={{ uri: images.deleteIconBlack }}
                  resizeMode="contain"
                  style={{ width: widthS * 10, height: widthS * 10 }}
                />
              </Pressable>
              {/* ------------ draft save btn -------------- */}
              {/* {!dashboard && isEdited.current && <Pressable
                onPress={() => {
                  if (type === "mail") {
                    dispatch(minimizeNewMail(false, false, id, true));
                  }
                  dispatch(dispatch(minimizeNewSystem(false, false, id, true)));
                  return;
                }}
                style={({ hovered }) => [
                  hovered ? styles.toolbar_btns_hovered : styles.toolbar_btns,
                  {},
                ]}
              >
                <Ionicons name="save-outline" size={"1.1vw"} color="black" />
              </Pressable>} */}
              {/* -------------------------------------------------- */}
              <View
                style={[styles.line, { backgroundColor: color.mailBorder2 }]}
              />
              <Pressable
                ref={fileRef}
                onPress={openFilesBrowser}
                style={({ hovered }) =>
                  hovered ? styles.toolbar_btns_hovered : styles.toolbar_btns
                }
                onHoverIn={() => setShowFileLimit(true)}
                onHoverOut={() => setShowFileLimit(false)}
              >
                <Image
                  source={{ uri: images.attachmentIconLogo }}
                  style={{
                    width: widthS * 9,
                    height: widthS * 9,
                    resizeMode: "contain",
                  }}
                />
                {showFileLimit && (
                  <FileLimitPopup
                    refObj={fileRef}
                  />
                )}
              </Pressable>
              <View
                style={{ minWidth: messageType === "drafts" ? "70%" : "90%" }}
                nativeID={createId(type, messageType)}
              />
            </View>
            <View style={styles.footer_child}>
              <View style={{ zIndex: 1, flex: 0.2 }}>
                {(() => {
                  let flag = newMessage.from_id && newMessage.to_list_ids && newMessage.subject && newMessage.body;
                  if (type !== "system" && !newMessage.event_id) {
                    flag = false;
                  }
                  return <Pressable
                    style={[
                      styles.send_btn,
                      {
                        backgroundColor: flag
                          ? background.mailBtnNew
                          : "#808080"
                      },
                    ]}
                    onPress={() => checkEnd(() => addNewMail())}
                  >
                    <Text selectable={false} style={styles.send_text}>
                      {t("send")}
                    </Text>
                  </Pressable>
                })()}
              </View>
            </View>
          </View>
          {/*)}*/}
        </>}
      </View>

    )
  };

  return (
    <>
      <View
        style={[
          styles.top,
          {
            borderBottomColor: color.border,
          },
        ]}
      >
        <Text selectable={false} style={styles.text_logo}>
          {t("new-message")}
        </Text>
        <HeaderPopupButtons
          close={() => {
            if (dashboard) {
              if (isEdited.current) {
                saveToDraftsHandler();
              }
              return setFlag();
            }
            if (type === "mail") {
              dispatch(minimizeNewMail(false, false, id, true));
            } else {
              dispatch(dispatch(minimizeNewSystem(false, false, id, true)));
            }
            return;
          }}
          // type={dashboard && true}
          maximize={maximize}
          maximizeFunc={(minimize || dashboard) ? null : () => setMaximize(!maximize)}
          minimize={dashboard ? () => minimizeDashboard(minimizeDashboardObject, isEdited.current ? newMessage : null) : () => {
            dispatch(
              type === "mail"
                ? minimizeNewMail(
                  false,
                  !!minimize,
                  id
                )
                : minimizeNewSystem(
                  false,
                  !!minimize,
                  id
                )
            );
          }}
        />
      </View>
      {body()}
    </>
  );
}

const stylesR = (heightS, widthS, background, color, direction) =>
  StyleSheet.create({
    top: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: widthS * 10,
      position: "relative",
      borderTopLeftRadius: widthS * 9,
      borderTopRightRadius: widthS * 9,
      borderBottomWidth: 2,
      zIndex: 8,
    },
    text_logo: {
      color: "white",
      fontFamily: "Catamaran_700Bold",
      fontSize: widthS * 12,
      paddingLeft: widthS * 8,
    },
    x_text: {
      fontFamily: "Catamaran_400Regular",
      fontSize: widthS * 14,
    },
    body: {
      display: "flex",
      flexDirection: "column",
      // paddingHorizontal: widthS * 8,
      zIndex: 9,
      // paddingTop: heightS * 4,
      height: "inherit"
    },
    body_m: {
      display: "flex",
      flexDirection: "column",
      paddingRight: widthS * 8,
      zIndex: 9,
      flex: 1,
      paddingTop: heightS * 4,
      minHeight: "99.5%",
    },
    containerBody: {
      borderBottomColor: color.mailBorder2,
      backgroundColor: background.mailBody,
      flex: 1,
      paddingHorizontal: "1vw",
      writingDirection: direction
    },
    header: {
      zIndex: 20,
      display: "flex",
      flexDirection: "column",
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
    },
    newMailPopup: {

    },
    from_view: {
      paddingVertical: "0.3vw",
      display: "flex",
      flexDirection: "row",
      minWidth: "15vw",
      alignItems: "center",
      zIndex: 13,
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
      // minHeight: heightS * 15,
    },
    label_for: {
      fontSize: "0.9vw",
      fontFamily: "Catamaran_800ExtraBold",
      marginRight: "0.3vw"
    },
    label_reply: {
      fontSize: widthS * 10,
      fontFamily: "Catamaran_500Medium",
    },
    label_text: {
      fontSize: "1vw",
      fontFamily: "Catamaran_600SemiBold",
      width: "96%",
    },
    label_text_input: {
      fontSize: "0.9vw",
      fontFamily: "Catamaran_400Regular",
      lineHeight: heightS * 12,
      maxHeight: heightS * 15,
      marginLeft: widthS * 3,
      display: "flex",
      alignItems: "center",
    },
    border_circle: {
      borderRadius: widthS * 25,
      //marginHorizontal: widthS * 3,
      marginLeft: widthS * 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    arrow: {
      position: "absolute",
      marginHorizontal: widthS * 5,
    },
    _btn: {
      borderRadius: widthS * 5,
      display: "flex",
      flexDirection: "column",
      zIndex: 3,
      width: "15vw",
      minWidth: "fit-content",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    _btn_open: {
      display: "flex",
      flexDirection: "column",
      zIndex: 3,
      width: "15vw",
      minWidth: "fit-content",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      borderTopLeftRadius: "0.3vw",
      borderTopRightRadius: "0.3vw",
    },
    _btn_head: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minHeight: "4vh",
      alignItems: "center",
    },
    _btn_body: {
      width: "100%",
      borderBottomLeftRadius: "0.3vw",
      borderBottomRightRadius: "0.3vw",
      borderTopWidth: 1,
      borderTopColor: color.mailBorder
    },
    shadow: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      elevation: 10,
    },
    to_view: {
      paddingVertical: "0.3vw",
      display: "flex",
      flexDirection: "row",
      zIndex: 12,
      alignItems: "center",
      minWidth: "15vw",
    },
    borderBottomHeader: {
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
    },
    to_view_child: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: "space-between",
      alignItems: "center",
      flex: 1,
    },
    cc_view: {
      paddingVertical: "0.3vw",
      display: "flex",
      flexDirection: "row",
      zIndex: 10,
      alignItems: "center",
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
      minWidth: "15vw",
    },
    event_view: {
      paddingVertical: "0.3vw",
      display: "flex",
      flexDirection: "row",
      minWidth: "15vw",
      zIndex: 9,
      alignItems: "center",
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
    },
    subject_view: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      paddingVertical: "0.3vw",
    },
    inp_subject: {
      width: "92%",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
      // maxWidth: "96%",
      // lineHeight: heightS * 12,
      height: "4vh",
    },
    attach_view: {
      display: "flex",
      flexDirection: "column",
      alignSelf: "flex-end",
      justifyContent: "space-between",
      marginBottom: heightS * 5,
      paddingHorizontal: widthS * 5,
      alignItems: "center",
    },
    scroll_c: {
      maxHeight: heightS * 270,
      display: "flex",
      zIndex: 9,
    },
    scroll_m: {
      zIndex: 9,
    },
    main: {
      paddingVertical: widthS * 5,
      display: "flex",
      zIndex: 9,
      fontFamily: "'Catamaran'",
      fontSize: "14px"
    },
    text_area: {
      borderWidth: 1,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
      outlineStyle: "none",
    },
    // webView: {
    // 	width: 200,
    // 	height: 200,
    // },
    footer: {
      display: "flex",
      flexDirection: "row",
      // paddingLeft: widthS * 10,
      // paddingRight: widthS * 10,
      padding: 10,
      alignItems: "center",
      justifyContent: "space-between",
      height: heightS * 23,
      borderTopWidth: 1,
      zIndex: 21,
    },
    footer_child: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // flex: 1,
      zIndex: 2,
    },
    toolbar_btns_hovered: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.9,
      shadowRadius: 2,
      width: widthS * 15,
      height: widthS * 14,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 2,
    },
    toolbar_btns: {
      width: widthS * 15,
      height: widthS * 14,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 2,
    },
    attach_name: {
      marginLeft: widthS * 5,
      fontSize: widthS * 11,
      fontFamily: "Catamaran_400Regular",
    },
    send_btn: {
      minWidth: widthS * 48,
      height: heightS * 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1,
      borderRadius: widthS * 5,
    },
    send_text: {
      color: "#ffffff",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
    draftsHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      height: heightS * 30,
      paddingRight: widthS * 18,
      zIndex: 8,
    },
    new_btn: {
      minWidth: widthS * 45,
      paddingHorizontal: widthS * 3,
      paddingVertical: heightS * 1.5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: widthS * 6,
    },
    new_text: {
      color: "#ffffff",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    search_press: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      borderRadius: widthS * 5,
      marginHorizontal: widthS * 4,
      paddingRight: widthS * 4,
    },
    search_input: {
      width: widthS * 155,
      paddingLeft: widthS * 10,
      paddingRight: widthS * 8,
      paddingVertical: heightS * 2,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_600SemiBold",
    },
    search_icon: {
      width: widthS * 9.8,
      height: widthS * 9.8,
      resizeMode: "contain",
    },
    body_child_c: {
      height: "100%",
      maxHeight: "100%",
    },
    body_child: {
      minHeight: "100.1%",
    },
    line: {
      height: heightS * 15,
      borderRadius: widthS * 3,
      // marginTop: heightS * 4,
      // marginBottom: heightS * 2,
      marginHorizontal: widthS * 3,
      width: widthS * 0.7,
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
    },
    popup_list: {
      maxHeight: heightS * 120,
      minHeight: heightS * 20,
      zIndex: 100,
    },
    popup_list_content: {
      // backgroundColor: "#ebebeb",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      borderRadius: widthS * 9,
    },
    /*label_for: {
      fontSize: widthS * 9,
      fontFamily: "Catamaran_600SemiBold",
      paddingLeft: widthS * 3,
      margin: 5,
    },*/
    list_item: {
      zIndex: 13,
      paddingHorizontal: "0.3vw",
      paddingVertical: "0.2vh",
      // width: widthS * 400,
    },
    list_item_text: {
      zIndex: 13,
      marginVertical: "0.3vh",
      marginLeft: widthS * 7,
      fontSize: "0.9vw",
      lineHeight: "1.1vw",
      fontFamily: "Catamaran_500Medium",
      display: "flex",
      alignItems: "center",
    },
    search_bar_pressable: {
      flex: 1
    },
    eventColor: {
      height: "1.8vh",
      width: "0.15vw",
      marginHorizontal: "0.3vw"
    },
    list_item_text_event: {
      zIndex: 13,
      fontSize: "0.9vw",
      lineHeight: "1.1vw",
      fontFamily: "Catamaran_600SemiBold,",
      display: "flex",
      alignItems: "center",
    },
    viewItems: {
      flexDirection: "row",
      flexWrap: "wrap",
      maxHeight: "8vh",
      overflowY: "scroll",
      maxWidth: "100%"
    },
    viewItem: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: background.mailPopupItems,
      paddingLeft: direction === "rtl" ? 0 : "0.5vw",
      paddingRight: direction !== "rtl" ? 0 : "0.5vw",
      paddingVertical: "0.2vw",
      borderRadius: "0.3vw"
    },
    viewItemWrap: {
      margin: "0.1vw"
    },
    x_item: {
      paddingHorizontal: "0.6vw",
      fontSize: "0.7vw",
      color: color.x,
      fontFamily: "Catamaran_500Medium"
    }
  });