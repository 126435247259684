import React, { useMemo, useCallback } from 'react';
import { View, Image, Text, StyleSheet, Pressable } from 'react-native';
import { useSelector } from 'react-redux';
import { vwToPx } from '../../modules/ConvertPXToVw';
import { Tooltip } from 'antd';
import { FlatList } from 'react-native-web';

const PlayerImages = ({ players, e, index, popupToList, containerRef, hover, setHover, onPress, isVisible, tooltipRef }) => {

  const calculateTooltipPosition = useCallback(() => {
    if (popupToList.current[index] && containerRef.current) {
      const rect = popupToList.current[index].getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      return {
        x: rect.x - (containerRect.x + vwToPx(5.5)) + (rect.width / 3),
        y: rect.y - (containerRect.y - vwToPx(2)),
      };
    }
    return { x: 0, y: 0 };
  }, [popupToList.current[index], containerRef.current]);

  const to_list_names_all = useMemo(() => players.filter((plr) => e.to_list?.indexOf(plr?.id) !== -1), [players]);
  const displayedPlayers = to_list_names_all.slice(0, 4);
  const extraPlayers = to_list_names_all.length - 4;

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(background, color);

  const getInitials = (name) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase() + nameParts[0][1]?.toUpperCase();
    } else if (nameParts.length > 1) {
      return nameParts[0][0].toUpperCase() + nameParts[1][0]?.toUpperCase();
    }
    return '';
  };

  const handleHoverIn = () => {
    if (!hover && isVisible) {
      const { x, y } = calculateTooltipPosition();
      setHover({
        x, y,
        to_list: e.to_list,
        ref: popupToList.current[index]
      });
    } else {
      setHover(false);
    }
  };

  const tooltipContent = (
    <View style={{ maxWidth: '15vw', maxHeight: '10vw', textAlign: direction, overflow: 'auto', padding: "'0.3vw'" }}>
      <FlatList
        data={to_list_names_all}
        keyExtractor={(player) => player.id.toString()}
        renderItem={({ item }) => (
          <View style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: '0.3vw' }}>
            <Text style={{
              fontSize: "0.9vw",
              writingDirection: direction,
              color: color.text2,
            }}>{item.name_for_game}</Text>
          </View>
        )}
        contentContainerStyle={{ padding: '0.5vw' }}
      />
    </View>
  );

  return (
    <Tooltip
      title={tooltipContent}
      overlayStyle={{
        width: "15vw",
        backgroundColor: background.infoBox, // רקע מותאם אישית
        paddingHorizontal: "1vw",
        paddingVertical: "0.5vw",
        borderRadius: "0.5vw",
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.5)',
        writingDirection: direction,
      }}
      arrow={false}
      placement="bottom"
      trigger={['hover']}
    >
      <Pressable
        ref={(ref) => ref && !popupToList.current[index] && popupToList.current.push(ref)}
        onPress={onPress}
        // onHoverIn={handleHoverIn}
        // onHoverOut={() => setHover(false)}
        style={styles.container}
      >
        <>
          {displayedPlayers.map((player, index) => (
            <View
              style={[
                styles.imgView,
                {
                  ...(direction === "ltr"
                    ? { marginLeft: index > 0 ? '-0.2vw' : 0 }
                    : { marginRight: index > 0 ? '-0.2vw' : 0 }),
                  zIndex: displayedPlayers.length - index
                }
              ]}
              key={index}
            >
              {player?.profile_image ? (
                <Image
                  source={player.profile_image}
                  style={styles.logo}
                />
              ) : (
                <View style={styles.initialsView}>
                  <Text style={styles.initialsText}>{getInitials(player.name_for_game)}</Text>
                </View>
              )}
            </View>
          ))}
          {extraPlayers > 0 && (
            <View style={direction === "ltr" ? { marginLeft: '0.3vw' } : { marginRight: "0.3vw" }}>
              <Text style={styles.extraText}>+{extraPlayers}</Text>
            </View>
          )}
        </>
      </Pressable>
    </Tooltip>
  );
};

const stylesR = (background, color) => StyleSheet.create({
  container: {
    width: '12vw',
    maxWidth: '12vw',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  imgView: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '0.1vw',
    backgroundColor: background.chatCreatorBorder,
    overflow: 'hidden',
    width: '2vw',
    height: '2vw',
  },
  logo: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: '50%',
  },
  initialsView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: background.infoBody,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  initialsText: {
    color: color.text2,
    fontWeight: "500",
    fontSize: '0.9vw',
  },
  extraView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: background.chatCreatorBorder,
  },
  extraText: {
    color: color.text2,
    fontSize: '0.9vw',
  },
});

export default PlayerImages;
